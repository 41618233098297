import { Fragment } from 'react';
import { BackToOverview } from '../../../Components/BackToOverview';

export const Step5 = () => {
    return (
        <Fragment>
            <h1>Vielen Dank für Ihre Bestellung.</h1>
            <p>Vielen Dank für Ihre Bestellung der Malteser Pflegebox und das damit verbundene Vertrauen in uns. Sie erhalten Ihre Bestellübersicht per E-Mail an die von Ihnen angegeben E-Mail-Adresse. Eine Kopie Ihres Kostenübernahmeantrags an die Pflegekasse als pdf-Datei finden Sie in Ihren Downloads. Wir übernehmen die Abwicklung mit der Pflegekasse für Sie. Die Datei ist nur für Ihre Unterlagen gedacht.</p>
            <p>Die erste Lieferung Ihrer Pflegebox erfolgt innerhalb von 5-10 Werktagen.</p>
            <p>Die Malteser Pflegebox ist so konfiguriert, dass mit bewilligter Kostenübernahme durch die Pflegekasse keine Kosten für die Pflegebedürftigen entstehen.</p>
            <p>
                Sie können Ihre Pflegebox jederzeit verändern, pausieren oder kündigen unter{' '}
                <a href="https://www.malteser.de/pflegebox" title="Link zur Malteser Pflegebox">
                    www.malteser.de/pflegebox
                </a>
            </p>
            <p>
                Außerdem erreichen Sie uns telefonisch: <br />
                <strong>0800 9966032 (Mo.-Fr. von 08:00-18:00 Uhr)</strong>
            </p>
            <p>
                Mit freundlichen Grüßen <br />
                Ihre Malteser
            </p>

            <BackToOverview />
        </Fragment>
    );
};
