import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';

type MacaptchaStateType = {
    token: string;
    setToken: (token: string) => void;
};

export const useMcaptchaState = create<MacaptchaStateType>()(
    immer(
        devtools(
            (set, get) => ({
                token: '',
                setToken: (token: string) =>
                    set(
                        (state) => {
                            state.token = token;
                        },
                        false,
                        { type: 'setToken' }
                    ),
            }),
            { name: 'McaptchaState', enabled: HelperService.isDevelopment }
        )
    )
);
