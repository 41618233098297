import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { ChangeFormInputsStep1, ChangeFormInputsStep2, ChangeFormInputsStep3, ChangeFormStateActions, ChangeFormState as ChangeFormStateType, FullChangeForm } from '../Types/Change';

let prefilledDefaultChangeFormState = {
    step1: {
        bedProtectionInserts: '1',
        products: [],
    },
    step2: {
        orderFirstName: 'Max',
        orderLastName: 'Mustermann',
        email: 'demo@demo.de',
        emailCopy: 'demo@demo.de',
        phone: '123456',
        firstName: 'Stefan',
        lastName: 'Sonnenschein',
        street: 'Musterstraße',
        houseNumber: '12a',
        zip: '12345',
        city: 'Musterhausen',
        birthdate: '1990-12-12',
        deliveryAddress: true,
        deliveryFirstName: 'Martin',
        deliveryLastName: 'Musterfrau',
        deliveryAddressAddition: 'Paketstation',
        deliveryStreet: 'Demostraße',
        deliveryHouseNumber: '2b',
        deliveryZip: '12345',
        deliveryCity: 'Musterhausen',
    },
    step3: {
        agb: 0,
        revocation: 0,
        token: '',
    },
};

export const defaultChangeFormState = {
    step1: {
        bedProtectionInserts: '',
        products: [],
    },
    step2: {
        orderFirstName: '',
        orderLastName: '',
        email: '',
        emailCopy: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        birthdate: '',
        //deliveryAddress: '',
        deliveryFirstName: undefined,
        deliveryLastName: undefined,
        deliveryAddressAddition: undefined,
        deliveryStreet: undefined,
        deliveryHouseNumber: undefined,
        deliveryZip: undefined,
        deliveryCity: undefined,
    },
    step3: {
        agb: 0,
        revocation: 0,
        token: '',
    },
};

// function that sets pre filled fields for order form in dev mode
const getDefaultChangeFormFields = () => {
    if (HelperService.isDevelopment) {
        return prefilledDefaultChangeFormState as ChangeFormStateType;
    }
    return defaultChangeFormState;
};
export const useChangeFormState = create<ChangeFormStateType & ChangeFormStateActions>()(
    immer(
        devtools(
            (set, get) => ({
                ...getDefaultChangeFormFields(),
                setStep1: (step1Form: ChangeFormInputsStep1) =>
                    set(
                        (state) => {
                            state.step1 = step1Form;
                        },
                        false,
                        { type: 'setStep1' }
                    ),
                setStep2: (step2Form: ChangeFormInputsStep2) =>
                    set(
                        (state) => {
                            state.step2 = step2Form;
                        },
                        false,
                        { type: 'setStep2' }
                    ),
                setStep3: (step3Form: ChangeFormInputsStep3) =>
                    set(
                        (state) => {
                            state.step3 = step3Form;
                        },
                        false,
                        { type: 'setStep3' }
                    ),
                fullFormState: () => {
                    return { ...get().step1, ...get().step2, ...get().step3 } as FullChangeForm;
                },
                resetChangeForm: () =>
                    set(
                        (state) => {
                            state.step1 = defaultChangeFormState.step1;
                            state.step2 = defaultChangeFormState.step2;
                            state.step3 = defaultChangeFormState.step3;
                        },
                        false,
                        { type: 'resetChangeForm' }
                    ),
            }),
            { name: 'ChangeFormState', enabled: HelperService.isDevelopment }
        )
    )
);
