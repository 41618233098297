import { format } from 'date-fns';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { PauseFormInputsStep1, PauseFormInputsStep2, PauseFormInputsStep3, PauseFormStateActions, PauseFormState as PauseFormStateType, FullPauseForm } from '../Types/Pause';

let prefilledDefaultPauseFormState = {
    step1: {
        pauseStart: null,
        pauseEnd: null,
    },
    step2: {
        orderFirstName: 'Max',
        orderLastName: 'Mustermann',
        email: 'demo@demo.de',
        emailCopy: 'demo@demo.de',
        phone: '123456',
        firstName: 'Stefan',
        lastName: 'Sonnenschein',
        street: 'Musterstraße',
        houseNumber: '12a',
        zip: '12345',
        city: 'Musterhausen',
        birthdate: '1990-12-12',
    },
    step3: {
        agb: 0,
        revocation: 0,
        token: '',
    },
};

export const defaultPauseFormState = {
    step1: {
        pauseStart: null,
        pauseEnd: null,
    },
    step2: {
        orderFirstName: '',
        orderLastName: '',
        email: '',
        emailCopy: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        birthdate: '',
    },
    step3: {
        agb: 0,
        revocation: 0,
        token: '',
    },
};

// function that sets pre filled fields for order form in dev mode
const getDefaultPauseFormFields = () => {
    if (HelperService.isDevelopment) {
        return prefilledDefaultPauseFormState as PauseFormStateType;
    }
    return defaultPauseFormState;
};
export const usePauseFormState = create<PauseFormStateType & PauseFormStateActions>()(
    immer(
        devtools(
            (set, get) => ({
                ...getDefaultPauseFormFields(),
                setStep1: (step1Form: PauseFormInputsStep1) =>
                    set(
                        (state) => {
                            state.step1 = step1Form;
                        },
                        false,
                        { type: 'setStep1' }
                    ),
                setStep2: (step2Form: PauseFormInputsStep2) =>
                    set(
                        (state) => {
                            state.step2 = step2Form;
                        },
                        false,
                        { type: 'setStep2' }
                    ),
                setStep3: (step3Form: PauseFormInputsStep3) =>
                    set(
                        (state) => {
                            state.step3 = step3Form;
                        },
                        false,
                        { type: 'setStep3' }
                    ),
                fullFormState: () => {
                    const pauseStartFormatted = get().step1.pauseStart instanceof Date ? format(get().step1.pauseStart as Date, 'yyyy-MM-dd') : null;
                    const pauseEndFormatted = get().step1.pauseEnd instanceof Date ? format(get().step1.pauseEnd as Date, 'yyyy-MM-dd') : null;
                    return { ...get().step1, ...get().step2, ...get().step3, pauseStart: pauseStartFormatted, pauseEnd: pauseEndFormatted } as FullPauseForm;
                },
                resetPauseForm: () =>
                    set(
                        (state) => {
                            state.step1 = defaultPauseFormState.step1;
                            state.step2 = defaultPauseFormState.step2;
                            state.step3 = defaultPauseFormState.step3;
                        },
                        false,
                        { type: 'resetPauseForm' }
                    ),
            }),
            { name: 'PauseFormState', enabled: HelperService.isDevelopment }
        )
    )
);
