import { PropsWithChildren } from 'react';
import HelperService from '../../Services/HelperService';
import { useBasketState } from '../../States/BasketState';
import { useChangeFormState } from '../../States/ChangeFormState';
import { useErrorState } from '../../States/ErrorState';
import { useMcaptchaState } from '../../States/McaptchaState';
import { useOrderFormState } from '../../States/OrderFormState';
import { usePauseFormState } from '../../States/PauseFormState';
import { useCurrentStepState } from '../../States/StepsState';
import { useWelcomePageState } from '../../States/WelcomePageState';
import './PagePagination.scss';

export const PagePagination: React.FC<PropsWithChildren> = ({ children }) => {
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const setBasketItems = useBasketState((state) => state.setBasketItems);
    const resetOrderForm = useOrderFormState((state) => state.resetOrderForm);
    const resetChangeForm = useChangeFormState((state) => state.resetChangeForm);
    const resetPauseForm = usePauseFormState((state) => state.resetPauseForm);
    const setToken = useMcaptchaState((state) => state.setToken);
    const setSelectedPage = useWelcomePageState((state) => state.setSelectedPage);
    const { setError } = useErrorState();

    const handleCurrentPageChange = () => {
        HelperService.scrollToStart();
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPageChange = () => {
        HelperService.scrollToStart();
        setToken('');
        setError(null);
        if (currentPage - 1 === 0) {
            setBasketItems([]);
            resetOrderForm();
            resetChangeForm();
            resetPauseForm();
            setSelectedPage(currentPage - 1);
        }
        setCurrentPage(currentPage - 1);
    };

    return (
        <div className="page-pagination menuservice-orderform-step">
            <div className="menuservice-orderform-buttonwrapper menuservice-orderform-buttonwrapper--step1-back">
                <span className="menuservice-orderform-step__back button--form" onClick={handlePreviousPageChange}>
                    Zurück
                </span>
            </div>
            {children ? (
                <div className="menuservice-orderform-buttonwrapper menuservice-orderform-buttonwrapper--step1">{children}</div>
            ) : (
                <div className="menuservice-orderform-buttonwrapper menuservice-orderform-buttonwrapper--step1">
                    <button className="btn btn--red btn--large menuservice-orderform-submit align-right" onClick={handleCurrentPageChange}>
                        Nächste Seite
                    </button>
                </div>
            )}
        </div>
    );
};
