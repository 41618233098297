import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';

type ErrorStateType = {
    error: Error | null;
    setError: (error: Error | null) => void;
};

export const useErrorState = create<ErrorStateType>()(
    immer(
        devtools(
            (set) => ({
                error: null,
                setError: (error) => {
                    set(
                        (state) => {
                            state.error = error;
                        },
                        false,
                        { type: 'setError' }
                    );
                },
            }),
            { name: 'ErrorState', enabled: HelperService.isDevelopment }
        )
    )
);
