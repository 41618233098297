import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ApiService from '../../../Services/ApiService';
import HelperService from '../../../Services/HelperService';
import { useLoadingIndicatorState } from '../../../States/LoadingIndicator';
import { useMcaptchaState } from '../../../States/McaptchaState';
import { usePauseFormState } from '../../../States/PauseFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { PauseFormInputsStep3 } from '../../../Types/Pause';
import { MCaptchaWidget } from '../../MCaptcha';
import { PagePagination } from '../../PagePagination';
import { Step3FormValues } from './Step3FormValues';

export const Step3Form: React.FC = () => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        reset,
    } = useForm<PauseFormInputsStep3>();
    const { currentPage, setCurrentPage } = useCurrentStepState();

    const { setStep3, fullFormState } = usePauseFormState();
    const { token } = useMcaptchaState();
    const formFieldValues = watch();
    const pauseFormState = usePauseFormState((state) => state.step3);

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<PauseFormInputsStep3> = async () => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }
        setLoading(true);

        const response = (await ApiService.sendPause.post(fullFormState())) as Response;
        reset({
            ...formFieldValues,
            agb: 0,
            revocation: 0,
            token: '',
        });
        setLoading(false);
        if (response.status === 200) {
            setCurrentPage(currentPage + 1);
            HelperService.scrollToStart();
        }
    };

    const onHandleClickNextStep = (): void => {
        // set mCaptcha token to hidden field
        if (token) {
            setValue('token', token);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Datenübersicht</h2>
            <p>Bitte überprüfen Sie die Daten auf ihre Korrektheit.</p>

            <Step3FormValues />

            <div className="form" style={{ marginTop: '40px' }}>
                <div className="form__checkbox checkbox">
                    <input type="checkbox" id="agb" defaultChecked={pauseFormState.agb ? true : false} {...register('agb', { required: 'Bitte stimmen Sie den AGB zu.' })} />
                    <label className="powermail_label" htmlFor="agb">
                        <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                        <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox.html" title="Malteser Pflegebox AGB" target="_blank" rel="noreferrer">
                            AGB
                        </a>{' '}
                        gelesen und akzeptiere diese.
                    </label>
                </div>
                {errors.agb && (
                    <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                        {errors.agb.message}
                    </div>
                )}
            </div>

            <div className="form">
                <div className="form__checkbox checkbox">
                    <input type="checkbox" id="revocation" defaultChecked={pauseFormState.revocation ? true : false} {...register('revocation', { required: 'Bitte stimmen Sie dem Widerruf zu.' })} />
                    <label className="powermail_label" htmlFor="revocation">
                        <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                        <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox/widerrufsbelehrung.html" title="Malteser Pflegebox Widerrufsbelehrung" target="_blank" rel="noreferrer">
                            Widerrufsbelehrung
                        </a>{' '}
                        gelesen und akzeptiere diese.
                    </label>
                </div>
                {errors.revocation && (
                    <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                        {errors.revocation.message}
                    </div>
                )}
            </div>

            <input type="hidden" id="token" disabled={true} {...register('token', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
            {formFieldValues.revocation && formFieldValues.agb && <MCaptchaWidget />}

            <PagePagination>
                <button disabled={token === '' || !formFieldValues.revocation || !formFieldValues.agb} className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" onClick={onHandleClickNextStep}>
                    Pflegebox pausieren
                </button>
            </PagePagination>
        </form>
    );
};
