import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import HelperService from '../../../Services/HelperService';
import { useBasketState } from '../../../States/BasketState';
import { useChangeFormState } from '../../../States/ChangeFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ChangeFormInputsStep1 } from '../../../Types/Change';
import { ComponentWithBasket } from '../../../Types/Components';
import { PagePagination } from '../../PagePagination';

export const Step1Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm<ChangeFormInputsStep1>();
    const changeFormState = useChangeFormState((state) => state.step1);
    const setChangeFormStep1 = useChangeFormState((state) => state.setStep1);
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const basketItems = useBasketState((state) => state.basketItems);
    const currentBasketPriceIsInMinRange = useBasketState((state) => state.currentBasketPriceIsInMinRange);
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            let formFields = {
                ...formFieldValues,
                products: [...basketItems],
            };
            setChangeFormStep1(formFields);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<ChangeFormInputsStep1> = () => {
        if (Object.keys(formFieldValues).length > 0) {
            let formFields = {
                ...formFieldValues,
                products: [...basketItems],
            };
            setChangeFormStep1(formFields);
        }
        if (isValid || Object.keys(errors).length < 1) {
            HelperService.scrollToStart();
        }

        setCurrentPage(currentPage + 1);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>
                Möchten Sie zusätzlich waschbare Bettschutzeinlagen beantragen? <span className="powermail_mandatory malteserrot">*</span>
            </h3>
            <p>Zusätzlich zu Ihren Pflegehilfsmitteln haben Sie gegebenenfalls auch Anspruch auf wiederverwendbare Bettschutzeinlagen.</p>
            <p>Die Lieferung von wiederverwendbaren Bettschutzeinlagen erfolgt nach Genehmigung durch Ihre Pflegekasse und ist für Sie kostenfrei. Die gesetzliche Zuzahlung in Höhe von 10 Prozent übernehmen wir für Sie.</p>
            <div className="form__radio-container">
                <div className="form__radio radio">
                    <input type="radio" value="1" id="bedProtectionInsertsYes" defaultChecked={changeFormState.bedProtectionInserts === '1'} {...register('bedProtectionInserts', { required: 'Dieses Feld ist ein Pflichtfeld.', onChange: (e) => setChangeFormStep1({ ...formFieldValues, products: [...basketItems], bedProtectionInserts: `${e.target.value}` }) })} />
                    <label className="powermail_label" htmlFor="bedProtectionInsertsYes">
                        Ja
                    </label>
                    <span id="skipToMobileBasket"></span>
                    <input type="radio" value="0" id="bedProtectionInsertsNo" defaultChecked={changeFormState.bedProtectionInserts === '0'} {...register('bedProtectionInserts', { required: 'Dieses Feld ist ein Pflichtfeld.', onChange: (e) => setChangeFormStep1({ ...formFieldValues, products: [...basketItems], bedProtectionInserts: `${e.target.value}` }) })} />
                    <label className="powermail_label" htmlFor="bedProtectionInsertsNo">
                        Nein
                    </label>
                </div>

                {errors.bedProtectionInserts && (
                    <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '20px', color: 'red' }}>
                        {errors.bedProtectionInserts.message}
                    </div>
                )}
            </div>

            {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}

            <PagePagination>
                <button disabled={basketItems.length < 1 || !currentBasketPriceIsInMinRange} className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit">
                    Nächste Seite
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
