import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';

type LoadingIndicatorType = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
};

export const useLoadingIndicatorState = create<LoadingIndicatorType>()(
    immer(
        devtools(
            (set, get) => ({
                loading: false,
                setLoading: (loading: boolean) => set({ loading }),
            }),
            { name: 'LoadingIndicatorState', enabled: HelperService.isDevelopment }
        )
    )
);
