import { Fragment } from 'react';
import HelperService from '../../../Services/HelperService';
import { useOrderFormState } from '../../../States/OrderFormState';
import { useCurrentStepState } from '../../../States/StepsState';

export const Step4FormValues = () => {
    const orderFormState = useOrderFormState((state) => state.step2);
    const orderFormStep3 = useOrderFormState((state) => state.step3);
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);
    const date = new Date(orderFormState.birthdate);
    const formattedDate = date.toLocaleDateString('de-DE');

    const handleChangeData = () => {
        HelperService.scrollToStart();
        setCurrentPage(2);
    };

    return (
        <Fragment>
            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Ihre Daten als Besteller
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={handleChangeData}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{orderFormState.orderFirstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{orderFormState.orderLastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail:</strong>
                    </p>
                    <p>{orderFormState.email}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail bestätigen:</strong>
                    </p>
                    <p>{orderFormState.emailCopy}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Telefon:</strong>
                    </p>
                    <p>{orderFormState.phone}</p>
                </div>
            </div>

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Daten der pflegebedürftigen Person
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={handleChangeData}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{orderFormState.firstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{orderFormState.lastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stra&szlig;e:</strong>
                    </p>
                    <p>{orderFormState.street}</p>
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Hausnummer:</strong>
                            </p>
                            <p>{orderFormState.houseNumber}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>PLZ:</strong>
                            </p>
                            <p>{orderFormState.zip}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{orderFormState.city}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Geburtstag:</strong>
                    </p>
                    <p>{formattedDate}</p>
                </div>
            </div>

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Angaben zur Krankenkasse
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={handleChangeData}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="form__radio-container">
                <div className="form__radio radio">
                    {orderFormState.healthInsuranceType === '0' ? (
                        <Fragment>
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Gesetzlich</strong>
                            </p>
                            <p>Ja</p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Privat</strong>
                            </p>
                            <p>Ja</p>
                        </Fragment>
                    )}
                </div>
            </div>

            {orderFormState.healthInsuranceType !== '1' && (
                <div className="grid">
                    <div className="grid__column grid__column--sm-6 grid__column--md-6">
                        <div className="form__select-container">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Krankenkasse:</strong>
                            </p>
                            <p>{orderFormState?.healthInsurance}</p>
                        </div>
                    </div>
                    <div className="grid__column grid__column--sm-6 grid__column--md-6">
                        <p className="powermail_label" style={{ marginBottom: 0 }}>
                            <strong>Versicherungsnummer:</strong>
                        </p>
                        <p>{orderFormState.insuranceNumber}</p>
                    </div>
                </div>
            )}
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <div className="form__select-container">
                        <p className="powermail_label" style={{ marginBottom: 0 }}>
                            <strong>Pflegegrad:</strong>
                        </p>
                        <p>{orderFormState.levelOfCare}</p>
                    </div>
                </div>
            </div>

            {!(orderFormState.deliveryAddress !== true) && (
                <Fragment>
                    <div className="form">
                        <div className="form__checkbox checkbox">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Abweichende Lieferadresse</strong>
                            </p>
                            <p>{orderFormState.deliveryAddress ? 'Ja' : 'Nein'}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Vorname:</strong>
                            </p>
                            <p>{orderFormState.deliveryFirstName}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Nachname:</strong>
                            </p>
                            <p>{orderFormState.deliveryLastName}</p>
                        </div>
                    </div>
                    {orderFormState.deliveryAddressAddition && (
                        <div className="grid">
                            <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                                <p className="powermail_label" style={{ marginBottom: 0 }}>
                                    <strong>Adresszusatz:</strong>
                                </p>
                                <p>{orderFormState.deliveryAddressAddition}</p>
                            </div>
                        </div>
                    )}
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Stra&szlig;e:</strong>
                            </p>
                            <p>{orderFormState.deliveryStreet}</p>
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>Hausnummer:</strong>
                                    </p>
                                    <p>{orderFormState.deliveryHouseNumber}</p>
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>PLZ:</strong>
                                    </p>
                                    <p>{orderFormState.deliveryZip}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{orderFormState.deliveryCity}</p>
                </Fragment>
            )}

            <div className="form">
                <div className="form__checkbox checkbox">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Ihre Unterschrift:</strong>
                    </p>
                    <img src={orderFormStep3.signature} alt="signature" width={250} height={'auto'} style={{ border: '1px solid #000', marginTop: '10px' }} />
                </div>
            </div>
        </Fragment>
    );
};
