import { Fragment } from 'react';
import { BackToOverview } from '../../../Components/BackToOverview';

export const Step4 = () => {
    return (
        <Fragment>
            <h1>Änderung Ihrer Pflegebox.</h1>
            <p>
                Sie haben den Inhalt Ihrer Malteser Pflegebox geändert. Die Bestätigung darüber erhalten Sie per E-Mail an die von Ihnen angegebene E-Mail-Adresse. <br />
                Ihre Malteser Pflegebox mit dem geänderten Inhalt erhalten Sie ab dem kommenden Monat.
            </p>
            <p>Die Malteser Pflegebox ist so konfiguriert, dass mit bewilligter Kostenübernahme durch die Pflegekasse keine Kosten für die Pflegebedürftigen entstehen.</p>
            <p>
                Sie können Ihre Pflegebox jederzeit verändern, pausieren oder kündigen unter{' '}
                <a href="https://www.malteser.de/pflegebox" title="Link zur Malteser Pflegebox">
                    www.malteser.de/pflegebox
                </a>
            </p>
            <p>
                Außerdem erreichen Sie uns telefonisch: <br />
                <strong>0800 9966032 (Mo.-Fr. von 08:00-18:00 Uhr)</strong>
            </p>
            <p>
                Mit freundlichen Grüßen <br />
                Ihre Malteser
            </p>
            <BackToOverview />
        </Fragment>
    );
};
