import { Fragment } from 'react';
import { Step2Form } from '../../../Components/Termination/Forms/Step2Form';

export const Step2 = () => {
    return (
        <Fragment>
            <Step2Form />
        </Fragment>
    );
};
