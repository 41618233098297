const HelperService = {
    scrollToStart: (elementID = 'root') => {
        const rootElement = document.getElementById(elementID);
        const scrollTop = rootElement?.parentElement?.scrollTop ?? 0;
        window.scrollTo(0, scrollTop);
    },
    scrollToElement: (elementID = 'root') => {
        const scrollToElement = document.getElementById(elementID);
        if (scrollToElement) {
            scrollToElement.parentElement?.scrollIntoView();
        }
    },
    createFileDownload: (blob: Blob, filename: string) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    pattern: {
        emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        insuranceNumberRegex: /^[A-Z]{1}[0-9]{9}$/,
        zipCodeRegex: /^[0-9]{5}$/,
    },
    isDevelopment: process.env.NODE_ENV === 'development',
    isProduction: process.env.NODE_ENV === 'production',
};

export default HelperService;
