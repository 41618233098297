import React, { useEffect } from 'react';
import { useCurrentStepState } from '../../States/StepsState';
import { StepsProps } from '../../Types/Steps';

export const Steps: React.FC<StepsProps> = ({ steps }) => {
    const { currentPage, setCurrentPage } = useCurrentStepState();

    useEffect(() => {
        if (currentPage > steps.length) {
            setCurrentPage(steps.length);
        }
    }, [currentPage, setCurrentPage, steps]);

    return (
        <div className="menuservice-orderform-step-indicator">
            <div className="menuservice-orderform-step-indicator-wrapper">
                {steps.map((stepTitle, index) => {
                    return (
                        <span key={`${stepTitle}-${index}`} className={`menuservice-orderform-step-indicator-step ${currentPage >= index + 1 ? 'menuservice-orderform-step-indicator-step--active' : ''}`}>
                            <span className="menuservice-orderform-step-indicator-step-number">
                                <span className="menuservice-orderform-step-indicator-step-wrapper">{currentPage > index + 1 ? <i className="icon-check"></i> : <span>{index + 1}</span>}</span>
                            </span>
                            <span className="menuservice-orderform-step-indicator-step-info">{stepTitle}</span>
                        </span>
                    );
                })}
            </div>
        </div>
    );
};
