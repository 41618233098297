import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TerminationFormInputsStep2 } from '../../../Types/Termination';
import { Step2FormValues } from './Step2FormValues';

export const Step3Form: React.FC = () => {
    const { handleSubmit } = useForm<TerminationFormInputsStep2>();
    const onSubmit: SubmitHandler<TerminationFormInputsStep2> = async () => {};

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Folgende Erklärung haben Sie abgegeben:</h2>
            <Step2FormValues editable={false} />
        </form>
    );
};
