import { startOfMonth, addDays, addMonths, setHours } from 'date-fns';
import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { SubmitHandler, useForm } from 'react-hook-form';
import '../../../Components/DatePicker/DatePicker.scss';
import HelperService from '../../../Services/HelperService';
import { usePauseFormState } from '../../../States/PauseFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { PauseFormInputsStep1 } from '../../../Types/Pause';
import { PagePagination } from '../../PagePagination';

export const Step1Form: React.FC = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm<PauseFormInputsStep1>();
    const setPauseFormStep1 = usePauseFormState((state) => state.setStep1);
    const { pauseStart, pauseEnd } = usePauseFormState((state) => state.step1);

    const { currentPage, setCurrentPage } = useCurrentStepState();
    const formFieldValues = watch();

    const handleChange = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
        const pauseStart = newStartDate ? setHours(startOfMonth(newStartDate), 0) : null;
        if (newStartDate && newEndDate) {
            // Limit the endDate to a maximum of 6 months from the startDate
            const maxEndDate = addMonths(newStartDate, 6);
            const pauseEnd = newEndDate > maxEndDate ? maxEndDate : newEndDate;
            setPauseFormStep1({ pauseEnd: pauseEnd, pauseStart: pauseStart });
        } else {
            setPauseFormStep1({ pauseEnd: newEndDate, pauseStart: pauseStart });
        }
    };

    const onSubmit: SubmitHandler<PauseFormInputsStep1> = () => {
        if (Object.keys(formFieldValues).length > 0) {
            setPauseFormStep1(formFieldValues);
        }
        if (isValid || Object.keys(errors).length < 1) {
            HelperService.scrollToStart();
        }
        setCurrentPage(currentPage + 1);
    };

    const onHandleClickNextStep = (): void => {
        // check if form has no errors
        if (pauseStart) {
            setValue('pauseStart', pauseStart);
        }

        if (pauseEnd) {
            setValue('pauseEnd', pauseEnd);
        }
    };

    // generate a javascript function which adds a month to an given date
    const addMonthToDate = (date: Date | string): Date => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    };

    return (
        <Fragment>
            <p>
                Sie können die Lieferung unserer Pflegebox pausieren. Das geht frühestens ab dem Folgemonat und längstens für sechs Monate. <br />
                Wenn Sie nur einen Monat pausieren möchten, müssen Sie diesen Monat 2x klicken, damit Sie mit Ihrer Bestellung fortfahren können.
            </p>
            <p>Bitte geben Sie an, ab welchem Monat und bis zu welchem Monat (einschließlich) die Pflegeboxlieferung pausiert werden soll:</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="grid__column grid__column--sm-6 grid__column--md-5 grid__column--lg-4">
                        <input type="hidden" id="pauseStart" disabled={true} {...register('pauseStart', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                        <input type="hidden" id="pauseEnd" disabled={true} {...register('pauseEnd', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                        <DatePicker selected={null} onChange={handleChange} selectsRange minDate={startOfMonth(addMonths(new Date(), 1))} startDate={pauseStart as Date} endDate={pauseEnd as Date} dateFormat="dd/MM/yyyy" maxDate={pauseStart ? addDays(pauseStart as Date, 180) : addDays(new Date(), 720)} showMonthYearPicker inline />
                        {errors.pauseStart && (
                            <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                {errors.pauseStart.message}
                            </div>
                        )}
                    </div>
                    {pauseEnd && (
                        <div className="grid__column grid__column--sm-6 grid__column--md-7 grid__column--lg-8">
                            <p dangerouslySetInnerHTML={{ __html: `Ihre nächste reguläre Lieferung nach der Pausierung erfolgt im <b>${addMonthToDate(pauseEnd).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })}</b>` }}></p>
                        </div>
                    )}
                </div>
                <PagePagination>
                    <button disabled={!pauseStart || !pauseEnd} className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" onClick={onHandleClickNextStep}>
                        Nächste Seite
                    </button>
                </PagePagination>
            </form>
        </Fragment>
    );
};
