import HelperService from '../../Services/HelperService';
import { useBasketState } from '../../States/BasketState';
import { useChangeFormState } from '../../States/ChangeFormState';
import { useMcaptchaState } from '../../States/McaptchaState';
import { useOrderFormState } from '../../States/OrderFormState';
import { usePauseFormState } from '../../States/PauseFormState';
import { useCurrentStepState } from '../../States/StepsState';
import { useWelcomePageState } from '../../States/WelcomePageState';

export const BackToOverview = () => {
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);
    const setBasketItems = useBasketState((state) => state.setBasketItems);
    const resetOrderForm = useOrderFormState((state) => state.resetOrderForm);
    const resetChangeForm = useChangeFormState((state) => state.resetChangeForm);
    const resetPauseForm = usePauseFormState((state) => state.resetPauseForm);
    const setToken = useMcaptchaState((state) => state.setToken);
    const setSelectedPage = useWelcomePageState((state) => state.setSelectedPage);

    const handleBackToOverviewClick = () => {
        HelperService.scrollToStart();
        setBasketItems([]);
        resetOrderForm();
        resetChangeForm();
        resetPauseForm();
        setToken('');
        setSelectedPage(0);
        setCurrentPage(1);

        // Redirect to /pflegebox
        window.location.href = '/pflegebox';
    };

    return (
        <button className="button button--red" onClick={handleBackToOverviewClick}>
            Zurück zur Pflegebox
        </button>
    );
};
