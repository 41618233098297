import HelperService from '../../Services/HelperService';
import { useBasketState } from '../../States/BasketState';
import { useChangeFormState } from '../../States/ChangeFormState';
import { useOrderFormState } from '../../States/OrderFormState';
import { useProductState } from '../../States/ProductsState';
import { useCurrentStepState } from '../../States/StepsState';
import './Basket.scss';

const PercentageBar = ({ percent, color = 'red' }: { percent: number; color: string }) => {
    return (
        <div className={`percentage percentage--${color}`} data-percentage={`${percent}%`}>
            <div style={{ width: `${percent > 100 ? 100 : percent}%` }}>
                <div style={{ clipPath: `polygon(0 0, ${percent > 100 ? 100 : percent}% 0, ${percent > 100 ? 100 : percent}% 100%, 0% 100%)` }}>{percent}%</div>
            </div>
            <span className="percentage__indicator" data-indicator="Mindestbestellmenge"></span>
        </div>
    );
};

export const Basket = () => {
    const { basketItems, currentBasketPrice, currentBasketPriceIsInMaxRange, currentBasketPriceIsInMinRange, currentBasketPricePercentageState } = useBasketState();
    const setBasketItems = useBasketState((state) => state.setBasketItems);
    const getProductItemById = useProductState((state) => state.getProductItemById);
    const orderFormState = useOrderFormState((state) => state.step1);

    const currentPage = useCurrentStepState((state) => state.currentPage);
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);

    const changeFormState = useChangeFormState((state) => state.step1);

    /**
     * Removes duplicated items from an array and returns it.
     * @param {number[]} array - The array to remove duplicates from.
     * @returns {number[]} The array without any duplicated items.
     */
    const removeDuplicates = (array: number[]): number[] => {
        return array.filter((item, index) => array.indexOf(item) === index);
    };

    /**
     * Counts the number of times a product appears in the basket state.
     *
     * @param {number} productId - The ID of the product to count.
     * @returns {number} The number of times the product appears in the basket state.
     */
    const countProduct = (productId: number): number => {
        return basketItems.filter((basketProductId) => basketProductId === productId).length;
    };

    /**
     * Removes a value from the basket state array.
     *
     * @param {number} productId - The ID of the product to be removed.
     * @return {void}
     */
    const removeValue = (productId: number): void => {
        basketItems.every((productItemId, index) => {
            if (productItemId === productId) {
                let basketStateCopy = [...basketItems];
                basketStateCopy.splice(index, 1);
                setBasketItems(basketStateCopy);
                return false;
            }
            return true;
        });
    };

    const handleChangeProducts = () => {
        setCurrentPage(1);
        if (window.matchMedia('(max-width: 769px)').matches) {
            const timeOut = setTimeout(() => {
                HelperService.scrollToElement('skipToMobileBasket');
                clearTimeout(timeOut);
            }, 50);
        } else {
            HelperService.scrollToStart();
        }
    };

    return (
        <div id="basket" className="contact-box basket">
            <h3>Telefonische Beratung</h3>
            <span className="phone teaser__icon--phone">
                <a href="tel:08009966032">0800 9966032 (kostenfrei)</a>
            </span>
            <hr />
            <h3>Warenkorb</h3>
            {!currentBasketPriceIsInMinRange ? <p className="error">Die Mindestbestellmenge wurde noch nicht erreicht.</p> : null}
            {currentBasketPriceIsInMaxRange ? <p className="error">Die Maximalbestellmenge wurde überschritten.</p> : null}
            <PercentageBar percent={currentBasketPricePercentageState} color={currentBasketPriceIsInMinRange ? 'green' : 'red'} />
            <p>{basketItems.length} Artikel im Warenkorb</p>
            <ul>
                {removeDuplicates(basketItems).map((basketItem) => {
                    const product = getProductItemById(basketItem);
                    if (!product) {
                        return null;
                    }
                    return (
                        <li key={product.id}>
                            <span>
                                {countProduct(product.id)}x {product.name}
                            </span>
                            &nbsp;
                            {currentPage < 2 && (
                                <button
                                    className="button button--red button--small"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        removeValue(product.id);
                                    }}>
                                    X
                                </button>
                            )}
                        </li>
                    );
                })}
            </ul>
            {HelperService.isDevelopment && <p>Aktueller Preis: {currentBasketPrice}€</p>}
            {(orderFormState.infoPackage === '1' || orderFormState.bedProtectionInserts === '1' || changeFormState.bedProtectionInserts === '1') && (
                <div>
                    <h4>Zusatzangebote</h4>
                    <ul>
                        {orderFormState.infoPackage === '1' && <li>Infomaterial „Sorglos zu Hause“</li>}
                        {(orderFormState.bedProtectionInserts || changeFormState.bedProtectionInserts) === '1' && <li>Waschbare Bettschutzeinlagen</li>}
                    </ul>
                </div>
            )}
            {currentPage > 1 && (
                <button disabled={basketItems.length < 1} className="button button--green" onClick={handleChangeProducts}>
                    Warenkorb anpassen
                </button>
            )}
        </div>
    );
};
