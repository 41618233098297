import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { FullOrderForm, OrderFormInputsStep1, OrderFormInputsStep2, OrderFormInputsStep3, OrderFormInputsStep4, OrderFormStateActions, OrderFormState as OrderFormStateType } from '../Types/Order';

let prefilledDefaultOrderFormState = {
    step1: {
        infoPackage: '1',
        bedProtectionInserts: '1',
        products: [],
    },
    step2: {
        orderFirstName: 'Max',
        orderLastName: 'Mustermann',
        email: 'demo@demo.de',
        emailCopy: 'demo@demo.de',
        phone: '123456',
        firstName: 'Stefan',
        lastName: 'Sonnenschein',
        street: 'Musterstraße',
        houseNumber: '12a',
        zip: '12345',
        city: 'Musterhausen',
        birthdate: '1990-12-12',
        healthInsuranceType: '0',
        healthInsurance: '30',
        insuranceNumber: 'A123456789',
        levelOfCare: '2',
        deliveryAddress: true,
        deliveryFirstName: 'Martin',
        deliveryLastName: 'Musterfrau',
        deliveryAddressAddition: 'Paketstation',
        deliveryStreet: 'Demostraße',
        deliveryHouseNumber: '2b',
        deliveryZip: '12345',
        deliveryCity: 'Musterhausen',
    },
    step3: {
        signature: '',
    },
    step4: {
        agb: 0,
        revocation: 0,
        advise: 0,
        privonly: 0,
        token: '',
    },
};

export const defaultOrderFormState = {
    step1: {
        infoPackage: '',
        bedProtectionInserts: '',
        products: [],
    },
    step2: {
        orderFirstName: '',
        orderLastName: '',
        email: '',
        emailCopy: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        birthdate: '',
        healthInsuranceType: '0',
        healthInsurance: '',
        insuranceNumber: '',
        levelOfCare: '',
        //deliveryAddress: '',
        deliveryFirstName: undefined,
        deliveryLastName: undefined,
        deliveryAddressAddition: undefined,
        deliveryStreet: undefined,
        deliveryHouseNumber: undefined,
        deliveryZip: undefined,
        deliveryCity: undefined,
    },
    step3: {
        signature: '',
    },
    step4: {
        agb: 0,
        revocation: 0,
        advise: 0,
        privonly: 0,
        token: '',
    },
};

// function that sets pre filled fields for order form in dev mode
const getDefaultOrderFormFields = () => {
    if (HelperService.isDevelopment) {
        return prefilledDefaultOrderFormState as OrderFormStateType;
    }
    return defaultOrderFormState;
};

export const useOrderFormState = create<OrderFormStateType & OrderFormStateActions>()(
    immer(
        devtools(
            (set, get) => ({
                ...getDefaultOrderFormFields(),
                setStep1: (step1Form: OrderFormInputsStep1) =>
                    set(
                        (state) => {
                            state.step1 = step1Form;
                        },
                        false,
                        { type: 'setStep1' }
                    ),
                setStep2: (step2Form: OrderFormInputsStep2) =>
                    set(
                        (state) => {
                            state.step2 = step2Form;
                        },
                        false,
                        { type: 'setStep2' }
                    ),
                setStep3: (step3Form: OrderFormInputsStep3) =>
                    set(
                        (state) => {
                            state.step3 = step3Form;
                        },
                        false,
                        { type: 'setStep3' }
                    ),
                setStep4: (step4Form: OrderFormInputsStep4) =>
                    set(
                        (state) => {
                            state.step4 = step4Form;
                        },
                        false,
                        { type: 'setStep4' }
                    ),
                fullFormState: () => {
                    return { ...get().step1, ...get().step2, ...get().step3, ...get().step4 } as FullOrderForm;
                },
                resetOrderForm: () =>
                    set(
                        (state) => {
                            state.step1 = defaultOrderFormState.step1;
                            state.step2 = defaultOrderFormState.step2;
                            state.step3 = defaultOrderFormState.step3;
                            state.step4 = defaultOrderFormState.step4;
                        },
                        false,
                        { type: 'resetOrderForm' }
                    ),
            }),
            { name: 'OrderFormState', enabled: HelperService.isDevelopment }
        )
    )
);
