import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { TerminationReason } from '../Types/TerminationReason';

type TerminationReasonStateType = {
    terminationReason: TerminationReason[];
    setTerminationReason: (terminationReason: TerminationReason[]) => void;
};

export const useTerminationReasonState = create<TerminationReasonStateType>()(
    immer(
        devtools(
            (set) => ({
                terminationReason: [],
                setTerminationReason: (terminationReason: TerminationReason[]) =>
                    set(
                        (state) => {
                            state.terminationReason = terminationReason;
                        },
                        false,
                        { type: 'setTerminationReason' }
                    ),
            }),
            { name: 'TerminationReasonState', enabled: HelperService.isDevelopment }
        )
    )
);
