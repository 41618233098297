import { Fragment } from 'react';
import { Step3Form } from '../../../Components/Pause/Forms/Step3Form';

export const Step3 = () => {
    return (
        <Fragment>
            <Step3Form />
        </Fragment>
    );
};
