import { Fragment } from 'react';
import { Basket } from '../../../Components/Basket';
import { Step1Form } from '../../../Components/Change/Forms/Step1Form';
import { ProductItem } from '../../../Components/ProductItem';
import { useProductState } from '../../../States/ProductsState';

export const Step1 = () => {
    const products = useProductState((state) => state.products);

    return (
        <Fragment>
            <h3>
                Hier können Sie den Inhalt Ihrer Malteser Pflegebox ändern. <br />
                Wählen Sie einfach aus, welche Pflegehilfsmittel Sie nun benötigen.
            </h3>
            <ul className="mb-40">
                <li>Unser Bestellsystem ist so konfiguriert, dass keine Kosten für die pflegebedürftige Person entstehen.</li>
                <li>Im Warenkorb wird Ihnen angezeigt, sobald die Mindestbestellwert erreicht ist.</li>
                <li>Wenn Sie den maximalen, monatlich erstattungsfähigen Bestellwert erreicht haben, können Sie keine Produkte mehr zum Warenkorb hinzufügen. Sie können also nicht zu viel bestellen.</li>
                <li>Es ist möglich, ein Produkt mehrfach zu bestellen. Klicken Sie bei diesem Produkt mehrfach auf den Button „Hinzufügen“.</li>
            </ul>

            <div className="grid">
                <div className="grid__column grid__column--sm-7 grid__column--md-8">
                    <div style={{ alignSelf: 'flex-start', width: '100%' }}>
                        <div style={{ marginBottom: '40px' }}>
                            <div className="grid">
                                {products.map((product, index) => {
                                    return (
                                        <div key={`${product.id}-${index}`} className="grid__column grid__column--lg-6">
                                            <ProductItem product={product} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Step1Form basket={<Basket />} />
                    </div>
                </div>
                <div className="grid__column grid__column--sm-5 grid__column--md-4 basket-grid">
                    <Basket />
                </div>
            </div>
        </Fragment>
    );
};
