import { Fragment, useEffect } from 'react';
import LoadingIndicator from '../Components/LoadingIndicator';
import ApiService from '../Services/ApiService';
import HelperService from '../Services/HelperService';
import { useHealthInsuranceState } from '../States/HealthInsurancesState';
import { useLoadingIndicatorState } from '../States/LoadingIndicator';
import { useProductState } from '../States/ProductsState';
import { useCurrentStepState } from '../States/StepsState';
import { useTerminationReasonState } from '../States/TerminationReasonState';
import { useWelcomePageState, useSelectedWelcomePageNameState } from '../States/WelcomePageState';
import { ChangePage } from './Change/ChangePage';
import { OrderPage } from './Order/OrderPage';
import { PausePage } from './Pause/PausePage';
import { TerminationPage } from './Termination/TerminationPage';

export const WelcomePage = () => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);
    const { products, setProducts } = useProductState();
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);
    const { selectedPage, setSelectedPage } = useWelcomePageState();
    const selectedWelcomePageName = useSelectedWelcomePageNameState((state) => state.selectedWelcomePageName);

    const healthInsurances = useHealthInsuranceState((state) => state.healthInsurances);
    const setHealthInsurances = useHealthInsuranceState((state) => state.setHealthInsurances);

    const terminationReason = useTerminationReasonState((state) => state.terminationReason);
    const setTerminationReason = useTerminationReasonState((state) => state.setTerminationReason);

    const handleUserSelection = (selectedPage: number) => {
        HelperService.scrollToStart();
        setCurrentPage(1);
        setSelectedPage(selectedPage);
    };

    const loadProducts = async () => {
        setLoading(true);
        const productsData = await ApiService.products.get();
        setProducts(productsData.data);
        setLoading(false);
    };

    const loadHealthInsurances = async () => {
        setLoading(true);
        const healthInsurancesData = await ApiService.healthInsurances.get();
        setHealthInsurances(healthInsurancesData.data);
        setLoading(false);
    };

    const loadTerminationReasons = async () => {
        setLoading(true);
        const terminationReasonData = await ApiService.terminationReasons.get();
        setTerminationReason(terminationReasonData.data);
        setLoading(false);
    };

    const selectPageBySearchParam = (searchParams: string) => {
        const urlSearchParams = new URLSearchParams(searchParams);
        const selectedPageParam = urlSearchParams.get('page');

        if (selectedWelcomePageName === searchParams) {
            return;
        }

        switch (selectedPageParam) {
            case 'order':
                setSelectedPage(1);
                break;
            case 'change':
                setSelectedPage(2);
                break;
            case 'pause':
                setSelectedPage(3);
                break;
            case 'termination':
                setSelectedPage(4);
                break;
            default:
                setSelectedPage(0);
                break;
        }
    };

    useEffect(() => {
        if (products.length < 1) {
            loadProducts();
        }

        if (healthInsurances.length < 1) {
            loadHealthInsurances();
        }

        if (terminationReason.length < 1) {
            loadTerminationReasons();
        }

        if (window.location.search) {
            const searchPageParamsValue = new URLSearchParams(window.location.search).get('page');
            if (searchPageParamsValue !== selectedWelcomePageName) {
                selectPageBySearchParam(window.location.search);
            }
        }
        // eslint-disable-next-line
    }, []);

    const getSelectedPage = () => {
        switch (selectedPage) {
            case 1:
                return <OrderPage />;
            case 2:
                return <ChangePage />;
            case 3:
                return <PausePage />;
            case 4:
                return <TerminationPage />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {selectedPage < 1 ? (
                <Fragment>
                    <h1>Herzlich willkommen bei der Malteser Pflegebox</h1>
                    <p>
                        Bestellen Sie hier die Pflegehilfsmittel für die private Nutzung zu Hause - ganz nach Ihrem Bedarf.
                        <br />
                        Ihre Pflegekasse übernimmt die monatlichen Kosten bis zum Maximalbetrag laut § 40 Abs. 2 SGB XI. Wir stellen den Kostenübernahmeantrag für Sie und kümmern uns um die Abwicklung mit Ihrer Pflegekasse. Hierfür benötigen wir Ihre Unterschrift.
                    </p>
                    <p className="mb-30">Bitte wählen Sie hier aus, ob Sie die Malteser Pflegebox bestellen, ändern, pausieren oder stornieren möchten.</p>
                    <button disabled={products.length < 1 || healthInsurances.length < 1} className="button button--red button--form" onClick={() => handleUserSelection(1)}>
                        Pflegebox bestellen
                    </button>
                    <button disabled={products.length < 1 || healthInsurances.length < 1} className="button button--red button--form" onClick={() => handleUserSelection(2)}>
                        Pflegebox ändern
                    </button>
                    <button className="button button--red button--form" onClick={() => handleUserSelection(3)}>
                        Pflegebox pausieren
                    </button>
                    <button className="button button--red button--form" onClick={() => handleUserSelection(4)}>
                        Pflegebox kündigen
                    </button>
                </Fragment>
            ) : (
                <Fragment>{getSelectedPage()}</Fragment>
            )}
            <LoadingIndicator />
        </Fragment>
    );
};
