import { useLoadingIndicatorState } from '../../States/LoadingIndicator';

export const LoadingIndicator = () => {
    const { loading } = useLoadingIndicatorState();
    return (
        <div className="processingIndicatorElement demo123" style={{ display: `${loading ? 'block' : 'none'}` }}>
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingIndicator;
