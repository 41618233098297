import { Fragment } from 'react';
import { Step1Form } from '../../../Components/Pause/Forms/Step1Form';

export const Step1 = () => {
    return (
        <Fragment>
            <Step1Form />
        </Fragment>
    );
};
