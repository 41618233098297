import { ALLOWED_MAX_PRICE, useBasketState } from '../../States/BasketState';
import { ProductProps } from '../../Types/Products';
import './ProductItem.scss';

export const ProductItem: React.FC<ProductProps> = ({ product }) => {
    const { addBasketItem, getBasketCountForProduct, currentBasketPrice, currentBasketPriceIsInMaxRange } = useBasketState();

    return (
        <div className="product">
            <div className="product__image">
                <img src={product.image} width={300} height={200} alt={product.name} />
            </div>
            <div className="product__content">
                <h4 className="product__title">{product.name}</h4>
                {product.description && <p className="product__description" dangerouslySetInnerHTML={{ __html: product.description }}></p>}
                <p className="product__count" style={{ marginBottom: '10px' }}>
                    {getBasketCountForProduct(product.id)}x im Warenkorb
                </p>
                <button className={`button ${currentBasketPriceIsInMaxRange || !(currentBasketPrice + product.price <= ALLOWED_MAX_PRICE) ? 'button--red' : 'button--green'} product__button`} disabled={currentBasketPriceIsInMaxRange || !(currentBasketPrice + product.price <= ALLOWED_MAX_PRICE)} onClick={() => addBasketItem(product.id)}>
                    Hinzufügen
                </button>
            </div>
        </div>
    );
};
