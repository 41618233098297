import { Fragment } from 'react';
import HelperService from '../../../Services/HelperService';
import { usePauseFormState } from '../../../States/PauseFormState';
import { useCurrentStepState } from '../../../States/StepsState';

export const Step3FormValues = () => {
    const pauseFormState2 = usePauseFormState((state) => state.step2);
    const pauseFormState1 = usePauseFormState((state) => state.step1);
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);

    const date = new Date(pauseFormState2.birthdate);
    const formattedDate = date.toLocaleDateString('de-DE');

    const pauseEndFormat = pauseFormState1.pauseEnd instanceof Date ? new Date(pauseFormState1.pauseEnd.getFullYear(), pauseFormState1.pauseEnd.getMonth() + 1, 0) : null;

    const handleChangeData = (pageIndex?: number) => {
        HelperService.scrollToStart();
        setCurrentPage(pageIndex ?? 2);
    };

    return (
        <Fragment>
            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Pflegebox pausieren
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(1)}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Start:</strong>
                    </p>
                    {pauseFormState1.pauseStart instanceof Date && <p>{pauseFormState1.pauseStart.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Ende:</strong>
                    </p>
                    {pauseEndFormat && <p>{pauseEndFormat.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>}
                </div>
            </div>
            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Ihre Daten als Besteller
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(2)}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{pauseFormState2.orderFirstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{pauseFormState2.orderLastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail:</strong>
                    </p>
                    <p>{pauseFormState2.email}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail bestätigen:</strong>
                    </p>
                    <p>{pauseFormState2.emailCopy}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Telefon:</strong>
                    </p>
                    <p>{pauseFormState2.phone}</p>
                </div>
            </div>

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Daten der pflegebedürftigen Person
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(2)}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{pauseFormState2.firstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{pauseFormState2.lastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stra&szlig;e:</strong>
                    </p>
                    <p>{pauseFormState2.street}</p>
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Hausnummer:</strong>
                            </p>
                            <p>{pauseFormState2.houseNumber}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>PLZ:</strong>
                            </p>
                            <p>{pauseFormState2.zip}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{pauseFormState2.city}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Geburtstag:</strong>
                    </p>
                    <p>{formattedDate}</p>
                </div>
            </div>

            {!(pauseFormState2.deliveryAddress !== true) && (
                <Fragment>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Vorname:</strong>
                            </p>
                            <p>{pauseFormState2.deliveryFirstName}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Nachname:</strong>
                            </p>
                            <p>{pauseFormState2.deliveryLastName}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Stra&szlig;e:</strong>
                            </p>
                            <p>{pauseFormState2.deliveryStreet}</p>
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>Hausnummer:</strong>
                                    </p>
                                    <p>{pauseFormState2.deliveryHouseNumber}</p>
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>PLZ:</strong>
                                    </p>
                                    <p>{pauseFormState2.deliveryZip}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{pauseFormState2.deliveryCity}</p>
                </Fragment>
            )}
        </Fragment>
    );
};
