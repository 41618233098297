import React, { useEffect } from 'react';

interface Props {
    step: number;
}

interface ExtendedWindow extends Window {
    dataLayer?: any[];
}

const tracking = [
    {
        event: 'virtual_page_view',
        page_path: '/pflegebox/kuendigen/daten',
        page_location: 'https://www.malteser.de/pflegebox/kuendigen/daten',
        page_title: 'Pflegebox – Kündigung – Daten',
    },
    {
        event: 'virtual_page_view',
        page_path: '/pflegebox/kuendigen/zusammenfassung',
        page_location: 'https://www.malteser.de/pflegebox/kuendigen/zusammenfassung',
        page_title: 'Pflegebox – Kündigung – Zusammenfassung',
    },
    {
        event: 'virtual_page_view',
        page_path: '/pflegebox/kuendigen/kuendigung-erfolgreich',
        page_location: 'https://www.malteser.de/pflegebox/kuendigen/kuendigung-erfolgreich',
        page_title: 'Pflegebox – Kündigung – Kündigung erfolgreich',
    },
];

export const TrackingComponent: React.FC<Props> = ({ step }) => {
    useEffect(() => {
        // Adjust step to start from 0
        const adjustedStep = step - 1;
        // Check if dataLayer exists and adjusted step is within valid range
        if (typeof window !== 'undefined' && (window as ExtendedWindow).dataLayer && adjustedStep >= 0 && adjustedStep < tracking.length) {
            const data = tracking[adjustedStep];
            const dataLayer = (window as ExtendedWindow).dataLayer;
            if (dataLayer) {
                // Construct the window.dataLayer push statement
                dataLayer.push({
                    event: data.event,
                    page_path: data.page_path,
                    page_location: data.page_location,
                    page_title: data.page_title,
                });
            }
        }
    }, [step]);

    return null;
};
