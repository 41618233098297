import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';

type WelcomePageStateType = {
    selectedPage: number;
    setSelectedPage: (selectedPage: number) => void;
};

const getSelectedPageIdByParam = (pageNumber: number) => {
    switch (pageNumber) {
        case 1:
            window.history.pushState('', '', `${window.location.pathname}?page=order`);
            return 'order';
        case 2:
            window.history.pushState('', '', `${window.location.pathname}?page=change`);
            return 'change';
        case 3:
            window.history.pushState('', '', `${window.location.pathname}?page=pause`);
            return 'pause';
        case 4:
            window.history.pushState('', '', `${window.location.pathname}?page=termination`);
            return 'termination';
        default:
            window.history.pushState('', '', window.location.pathname);
            return '';
    }
};

export const useWelcomePageState = create<WelcomePageStateType>()(
    immer(
        devtools(
            (set) => ({
                selectedPage: 0,
                setSelectedPage: (selectedPage: number) =>
                    set(
                        (state) => {
                            useSelectedWelcomePageNameState.getState().setSelectedWelcomePageName(getSelectedPageIdByParam(selectedPage));
                            state.selectedPage = selectedPage;
                        },
                        false,
                        { type: 'setSelectedPage' }
                    ),
            }),
            { name: 'WelcomePageState', enabled: HelperService.isDevelopment }
        )
    )
);

type SelectedWelcomePageNameState = {
    selectedWelcomePageName: string;
    setSelectedWelcomePageName: (selectedWelcomePageName: string) => void;
};

export const useSelectedWelcomePageNameState = create<SelectedWelcomePageNameState>()(
    immer(
        devtools(
            (set) => ({
                selectedWelcomePageName: '',
                setSelectedWelcomePageName: (selectedWelcomePageName: string) =>
                    set(
                        (state) => {
                            state.selectedWelcomePageName = selectedWelcomePageName;
                        },
                        false,
                        { type: 'setSelectedWelcomePageName' }
                    ),
            }),
            { name: 'SelectedWelcomePageNameState', enabled: HelperService.isDevelopment }
        )
    )
);
