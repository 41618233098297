import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { Product } from '../Types/Products';

type ProductsStateType = {
    products: Product[];
    setProducts: (products: Product[]) => void;
    getProductItemById: (id: number) => Product | undefined;
};

export const useProductState = create<ProductsStateType>()(
    immer(
        devtools(
            (set, get) => ({
                products: [],
                setProducts: (products: Product[]) =>
                    set(
                        (state) => {
                            state.products = products;
                        },
                        false,
                        { type: 'setProducts' }
                    ),
                getProductItemById: (id: number): Product | undefined => {
                    return get().products.find((productItem) => productItem.id === id);
                },
            }),
            { name: 'ProductsState', enabled: HelperService.isDevelopment }
        )
    )
);
