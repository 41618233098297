import React, { useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import HelperService from '../../../Services/HelperService';
import { useOrderFormState } from '../../../States/OrderFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ComponentWithBasket } from '../../../Types/Components';
import { OrderFormInputsStep3 } from '../../../Types/Order';
import { PagePagination } from '../../PagePagination';

export const Step3Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const signatureRef = useRef<SignatureCanvas>(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<OrderFormInputsStep3>();
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const { step3, setStep3 } = useOrderFormState();
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }

        if (step3['signature'] !== '') {
            signatureRef.current?.fromDataURL(step3['signature'], { width: 500, height: 200 });
            setValue('signature', step3['signature']);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<OrderFormInputsStep3> = async () => {
        if (signatureRef.current) {
            setValue('signature', signatureRef.current?.getTrimmedCanvas().toDataURL());
        }

        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }

        setCurrentPage(currentPage + 1);
    };

    const onHandleClickNextStep = (): void => {
        // check if form has no errors
        if (signatureRef.current?.getCanvas().toDataURL() || formFieldValues['signature'] !== '') {
            HelperService.scrollToStart();
        }
    };

    const onHandleSignatureFinished = () => {
        if (signatureRef.current) {
            setValue('signature', signatureRef.current?.getCanvas().toDataURL());
        }
    };

    const onHandleCleearSignature = () => {
        if (signatureRef.current) {
            setValue('signature', '');
            setStep3({ ...step3, signature: '' });
            signatureRef.current?.clear();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>
                Ihre Unterschrift <span className="powermail_mandatory malteserrot">*</span>
            </h2>
            <p>Ihre Unterschrift benötigen wir für den Kostenübernahmeantrag an die Pflegekasse. Wenn Sie für den Pflegebedürftigen unterschreiben, bestätigen Sie, dies in Vertretung und im Auftrag dessen zu tun.</p>
            <p className="mb-30">
                Des Weiteren willigen Sie darin ein, dass wir die Gesundheitsdaten für den Zweck der Beantragung der Kostenübernahme verarbeiten dürfen. Sie stimmen zu, die Datenschutzerklärung in ausführlicher Form zur Kenntnis genommen oder gegebenenfalls an die betroffene Person weitergegeben zu haben. Sie können diese{' '}
                <a href="https://www.malteser.de/datenschutz/pflegebox.html" title="Datenschutzinformationen zur Pflegebox" target="_blank" rel="noreferrer noopener">
                    hier
                </a>{' '}
                jederzeit einsehen.
            </p>
            <div>
                <input type="hidden" id="signature" disabled={true} {...register('signature', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                <div>
                    <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas', style: { border: '1px solid #333' } }} onEnd={onHandleSignatureFinished} />
                    <p>Bitte unterschreiben Sie in diesem Feld mit der Maus, dem Finger oder einem vorhandenen Pen.</p>
                </div>
                {errors.signature && (
                    <div className="powermail_error" style={{ marginTop: '0px', marginBottom: '20px', color: 'red' }}>
                        {errors.signature.message}
                    </div>
                )}
                <button className="button button--red" style={{ marginTop: '10px' }} onClick={onHandleCleearSignature}>
                    Unterschrift zurücksetzen
                </button>
            </div>

            {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" onClick={onHandleClickNextStep}>
                    Nächste Seite
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
