import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';

type CurrentStepState = {
    currentPage: number;
    setCurrentPage: (step: number) => void;
};

export const useCurrentStepState = create<CurrentStepState>()(
    immer(
        devtools(
            (set) => ({
                currentPage: 1,
                setCurrentPage: (step: number) =>
                    set(
                        (state) => {
                            state.currentPage = step;
                        },
                        false,
                        { type: 'setCurrentPage' }
                    ),
            }),
            { name: 'CurrentStepState', enabled: HelperService.isDevelopment }
        )
    )
);
