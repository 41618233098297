import { Fragment } from 'react';
import { useErrorState } from '../../States/ErrorState';

export const ErrorView = () => {
    const errorState = useErrorState((state) => state.error);

    return (
        <Fragment>
            <h1>Leider ist ein Fehler aufgetreten</h1>
            <div dangerouslySetInnerHTML={{ __html: `<p>${errorState?.message}</p>` }}></div>
        </Fragment>
    );
};
