import { Fragment } from 'react';
import { BackToOverview } from '../../../Components/BackToOverview';
import { Step3Form } from '../../../Components/Termination/Forms/Step3Form';

export const Step3 = () => {
    return (
        <Fragment>
            <h1>Kündigungserklärung Pflegebox.</h1>
            <Step3Form />
            <BackToOverview />
        </Fragment>
    );
};
