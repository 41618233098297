import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ApiService from '../../../Services/ApiService';
import HelperService from '../../../Services/HelperService';
import { useLoadingIndicatorState } from '../../../States/LoadingIndicator';
import { useMcaptchaState } from '../../../States/McaptchaState';
import { useCurrentStepState } from '../../../States/StepsState';
import { useTerminationFormState } from '../../../States/TerminationFormState';
import { TerminationFormInputsStep2 } from '../../../Types/Termination';
import { MCaptchaWidget } from '../../MCaptcha';
import { PagePagination } from '../../PagePagination';
import { Step2FormValues } from './Step2FormValues';

export const Step2Form: React.FC = () => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);
    const { register, handleSubmit, watch, setValue } = useForm<TerminationFormInputsStep2>();
    const { currentPage, setCurrentPage } = useCurrentStepState();

    const { setStep2, fullFormState } = useTerminationFormState();
    const { token, setToken } = useMcaptchaState();
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep2(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<TerminationFormInputsStep2> = async () => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep2(formFieldValues);
        }
        setLoading(true);

        const response = (await ApiService.sendTermination.post(fullFormState())) as Response;
        setValue('token', '');
        setToken('');
        setLoading(false);
        if (response.status === 200) {
            const blob = await response.blob();
            HelperService.createFileDownload(blob, 'Kündigungseingang.pdf');
            setCurrentPage(currentPage + 1);
            HelperService.scrollToStart();
        }
    };

    const onHandleClickNextStep = (): void => {
        // set mCaptcha token to hidden field
        if (token) {
            setValue('token', token);
        }
    };

    const time = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date());

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Datenübersicht</h2>
            <p>Bitte überprüfen Sie die Daten auf ihre Korrektheit.</p>

            <Step2FormValues />
            <input type="hidden" id="time" {...register('time', { required: 'Dieses Feld ist ein Pflichtfeld.' })} defaultValue={time} />
            <input type="hidden" id="token" disabled={true} {...register('token', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
            <MCaptchaWidget />

            <div className="grid">
                <div className="grid__column grid__column--xs-12">
                    <br />
                    <br />
                    <div className="h3-style">So geht es weiter:</div>
                    <p>
                        <b>1. Zusammenfassung Ihrer Kündigungserklärung</b>
                        <br /> Mit Klick auf „Jetzt kündigen“ gelangen Sie auf eine Zusammenfassungsseite, auf der Ihre Kündigungserklärung inkl. Datum und Uhrzeit angezeigt wird, sodass erkennbar ist, dass die Kündigungserklärung abgegeben wurde. Die Kündigungserklärung finden Sie zudem als PDF-Datei in Ihrem Download-Ordner für Ihre Unterlagen.
                    </p>{' '}
                    <p>
                        <b>2. Zugangsbestätigung Ihrer Kündigungserklärung</b>
                        <br /> Sie erhalten außerdem unmittelbar eine Bestätigung des Inhalts, sowie Datum und Uhrzeit der Kündigungserklärung, sowie des Zeitpunkts, zu dem das Vertragsverhältnis durch die Kündigung beendet werden soll, per E-Mail zugesandt.
                    </p>
                    <p>
                        <b>3. Kündigungsbestätigung</b>
                        <br /> Nach Prüfung der Kündigungserklärung erhalten Sie in den nächsten Tagen die Kündigungsbestätigung per E-Mail. Bitte beachten Sie, dass Ihre Daten ausschließlich für die Bearbeitung des Anliegens verwendet werden. <a href="https://www.malteser.de/datenschutz/pflegebox.html">Datenschutzhinweise</a>.
                    </p>
                </div>
                <div className="grid__column grid__column--xs-12">
                    <h3>Kündigung absenden</h3>
                    <p>Wenn Sie Ihre Kündigung absenden, finden Sie Ihre Kündigungserklärung als PDF-Datei in Ihren Downloads.</p>
                </div>
            </div>

            <PagePagination>
                <button disabled={token === ''} className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" onClick={onHandleClickNextStep}>
                    Jetzt kündigen
                </button>
            </PagePagination>
        </form>
    );
};
