import { Basket } from '../../../Components/Basket';
import { Step2Form } from '../../../Components/Order/Forms/Step2Form';

export const Step2 = () => {
    return (
        <div className="grid">
            <div className="grid__column grid__column--sm-7 grid__column--md-8">
                <div style={{ alignSelf: 'flex-start', width: '100%' }}>
                    <Step2Form basket={<Basket />} />
                </div>
            </div>
            <div className="grid__column grid__column--sm-5 grid__column--md-4 basket-grid">
                <Basket />
            </div>
        </div>
    );
};
