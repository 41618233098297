import { Basket } from '../../../Components/Basket';
import { Step3Form } from '../../../Components/Change/Forms/Step3Form';

export const Step3 = () => {
    return (
        <div className="grid">
            <div className="grid__column grid__column--sm-7 grid__column--md-8">
                <div style={{ alignSelf: 'flex-start', width: '100%' }}>
                    <Step3Form basket={<Basket />} />
                </div>
            </div>
            <div className="grid__column grid__column--sm-5 grid__column--md-4 basket-grid">
                <Basket />
            </div>
        </div>
    );
};
