import React, { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ApiService from '../../../Services/ApiService';
import HelperService from '../../../Services/HelperService';
import { useChangeFormState } from '../../../States/ChangeFormState';
import { useErrorState } from '../../../States/ErrorState';
import { useLoadingIndicatorState } from '../../../States/LoadingIndicator';
import { useMcaptchaState } from '../../../States/McaptchaState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ChangeFormInputsStep3 } from '../../../Types/Change';
import { ComponentWithBasket } from '../../../Types/Components';
import { ErrorView } from '../../ErrorView';
import { MCaptchaWidget } from '../../MCaptcha';
import { PagePagination } from '../../PagePagination';
import { Step3FormValues } from './Step3FormValues';

export const Step3Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        reset,
    } = useForm<ChangeFormInputsStep3>();
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const changeFormState = useChangeFormState((state) => state.step3);

    const { setStep3, fullFormState } = useChangeFormState();
    const { error: errorState, setError } = useErrorState();
    const { token, setToken } = useMcaptchaState();
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<ChangeFormInputsStep3> = async () => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep3(formFieldValues);
        }

        setLoading(true);
        try {
            const response = (await ApiService.sendChange.post(fullFormState())) as Response;
            setToken('');
            reset({
                ...formFieldValues,
                agb: 0,
                revocation: 0,
                token: '',
            });
            if (response.status === 200) {
                setCurrentPage(currentPage + 1);
                HelperService.scrollToStart();
            }
            if (response.status === 422) {
                const { error } = (await response.json()) as { error: { details: { message: string }[] } };
                let message = '';
                if (error && error?.details) {
                    error?.details.forEach((errorDetail) => {
                        message += `${errorDetail.message}<br/>`;
                    });
                }
                throw new Error(`Der Antrag kann leider nicht erstellt werden.<br/>${message}`);
            }
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const onHandleClickNextStep = (): void => {
        // set mCaptcha token to hidden field
        if (token) {
            setValue('token', token);
        }
    };

    useEffect(() => {
        if (token) {
            setValue('token', token);
        }
    }, [token, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {errorState ? (
                <ErrorView />
            ) : (
                <Fragment>
                    <h2>Datenübersicht</h2>
                    <p>Bitte überprüfen Sie die Daten auf ihre Korrektheit.</p>

                    <Step3FormValues />

                    <div className="form" style={{ marginTop: '40px' }}>
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="agb" defaultChecked={changeFormState.agb ? true : false} {...register('agb', { required: 'Bitte stimmen Sie den AGB zu.' })} />
                            <label className="powermail_label" htmlFor="agb">
                                <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                                <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox.html" title="Malteser Pflegebox AGB" target="_blank" rel="noreferrer">
                                    AGB
                                </a>{' '}
                                gelesen und akzeptiere diese.
                            </label>
                        </div>
                        {errors.agb && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.agb.message}
                            </div>
                        )}
                    </div>

                    <div className="form">
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="revocation" defaultChecked={changeFormState.revocation ? true : false} {...register('revocation', { required: 'Bitte stimmen Sie dem Widerruf zu.' })} />
                            <label className="powermail_label" htmlFor="revocation">
                                <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                                <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox/widerrufsbelehrung.html" title="Malteser Pflegebox Widerrufsbelehrung" target="_blank" rel="noreferrer">
                                    Widerrufsbelehrung
                                </a>{' '}
                                gelesen und akzeptiere diese.
                            </label>
                        </div>
                        {errors.revocation && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.revocation.message}
                            </div>
                        )}
                    </div>

                    <input type="hidden" id="token" disabled={true} {...register('token', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {formFieldValues.revocation && formFieldValues.agb && <MCaptchaWidget />}

                    {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}
                </Fragment>
            )}

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" disabled={token === '' || !formFieldValues.revocation || !formFieldValues.agb} type="submit" onClick={onHandleClickNextStep}>
                    &Auml;nderungen absenden
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
