import { Fragment } from 'react';
import { Steps } from '../../Components/Steps';
import { useCurrentStepState } from '../../States/StepsState';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { TrackingComponent } from './Steps/Tracking';

export const TerminationPage = () => {
    const currentPage = useCurrentStepState((state) => state.currentPage);

    const StepsArray = ['Daten', 'Zusammenfassung', 'Fertig'];

    const getCurrentPageComponent = () => {
        switch (currentPage) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <h1>Pflegebox kündigen</h1>
            <Steps steps={StepsArray} />
            <div>{getCurrentPageComponent()}</div>
            <TrackingComponent step={currentPage} />
        </Fragment>
    );
};
