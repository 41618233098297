import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { useProductState } from './ProductsState';

export const ALLOWED_MIN_PRICE = 25;
export const ALLOWED_MAX_PRICE = 40;

type BasketStateType = {
    basketItems: number[];
    currentBasketPrice: number;
    currentBasketPriceIsInMinRange: boolean;
    currentBasketPriceIsInMaxRange: boolean;
    currentBasketPricePercentageState: number;
    addBasketItem: (basketItem: number) => void;
    setBasketItems: (basket: number[]) => void;
    calculateCurrentBasketPrice: () => void;
    getBasketCountForProduct: (productId: number) => number;
};

export const useBasketState = create<BasketStateType>()(
    immer(
        devtools(
            (set, get) => ({
                basketItems: [],
                currentBasketPrice: 0,
                currentBasketPriceIsInMinRange: false,
                currentBasketPriceIsInMaxRange: false,
                currentBasketPricePercentageState: 0,
                addBasketItem: (basketItem: number) => {
                    set(
                        (state) => {
                            state.basketItems.push(basketItem);
                        },
                        false,
                        { type: 'addBasketItem' }
                    );
                    get().calculateCurrentBasketPrice();
                },
                setBasketItems: (basketItems: number[]) => {
                    set(
                        (state) => {
                            state.basketItems = basketItems;
                        },
                        false,
                        { type: 'setBasketItems' }
                    );
                    get().calculateCurrentBasketPrice();
                },
                calculateCurrentBasketPrice: () => {
                    const getCurrentPriceByBasketState = (): number => {
                        let currentPrice = 0;
                        for (const productId of get().basketItems) {
                            const product = useProductState.getState().getProductItemById(productId);
                            if (product) {
                                currentPrice += product.price;
                            }
                        }
                        return currentPrice;
                    };
                    let currentBasketPrice = getCurrentPriceByBasketState();

                    return set(
                        (state) => {
                            state.currentBasketPrice = currentBasketPrice;
                            state.currentBasketPriceIsInMaxRange = currentBasketPrice > ALLOWED_MAX_PRICE;
                            state.currentBasketPriceIsInMinRange = currentBasketPrice >= ALLOWED_MIN_PRICE;
                            state.currentBasketPricePercentageState = Math.round((currentBasketPrice / ALLOWED_MAX_PRICE) * 100);
                        },
                        false,
                        { type: 'calculateCurrentBasketPrice' }
                    );
                },
                getBasketCountForProduct: (productId: number) => get().basketItems.filter((basketProductId) => basketProductId === productId).length,
            }),
            { name: 'BasketState', enabled: HelperService.isDevelopment }
        )
    )
);
