import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { HealthInsurance } from '../Types/HealthInsurance';

type HealthInsuranceStateType = {
    healthInsurances: HealthInsurance[];
    setHealthInsurances: (healthInsurances: HealthInsurance[]) => void;
};

export const useHealthInsuranceState = create<HealthInsuranceStateType>()(
    immer(
        devtools(
            (set) => ({
                healthInsurances: [],
                setHealthInsurances: (healthInsurances: HealthInsurance[]) =>
                    set(
                        (state) => {
                            state.healthInsurances = healthInsurances;
                        },
                        false,
                        { type: 'setHealthInsurances' }
                    ),
            }),
            { name: 'HealthInsuranceState', enabled: HelperService.isDevelopment }
        )
    )
);
