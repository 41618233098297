import { Fragment } from 'react';
import { Steps } from '../../Components/Steps';
import { useCurrentStepState } from '../../States/StepsState';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { Step4 } from './Steps/Step4';
import { Step5 } from './Steps/Step5';
import { TrackingComponent } from './Steps/Tracking';

export const OrderPage = () => {
    const currentPage = useCurrentStepState((state) => state.currentPage);

    const StepsArray = ['Produktwahl', 'Daten', 'Unterschrift', 'Zusammenfassung', 'Fertig'];

    const getCurrentPageComponent = () => {
        switch (currentPage) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Step5 />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <h1>Pflegebox bestellen</h1>
            <Steps steps={StepsArray} />
            {getCurrentPageComponent()}
            <TrackingComponent step={currentPage} />
        </Fragment>
    );
};
