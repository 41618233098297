import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import HelperService from '../Services/HelperService';
import { Locations } from '../Types/Locations';

type LocationsStateType = {
    locations: Locations[];
    setLocations: (locations: Locations[]) => void;
    contractLocations: Locations[];
    setContractLocations: (locations: Locations[]) => void;
};

export const useLocationsStateType = create<LocationsStateType>()(
    immer(
        devtools(
            (set) => ({
                locations: [],
                setLocations: (locations: Locations[]) =>
                    set(
                        (state) => {
                            state.locations = locations;
                        },
                        false,
                        { type: 'setLocations' }
                    ),
                contractLocations: [],
                setContractLocations: (locations: Locations[]) =>
                    set(
                        (state) => {
                            state.contractLocations = locations;
                        },
                        false,
                        { type: 'setContractLocations' }
                    ),
            }),
            { name: 'LocationsStateType', enabled: HelperService.isDevelopment }
        )
    )
);
