import React, { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ApiService from '../../../Services/ApiService';
import HelperService from '../../../Services/HelperService';
import { useLoadingIndicatorState } from '../../../States/LoadingIndicator';
import { useLocationsStateType } from '../../../States/LocationsState';
import { useMcaptchaState } from '../../../States/McaptchaState';
import { useCurrentStepState } from '../../../States/StepsState';
import { useTerminationFormState } from '../../../States/TerminationFormState';
import { useTerminationReasonState } from '../../../States/TerminationReasonState';
import { Locations } from '../../../Types/Locations';
import { TerminationFormInputsStep1 } from '../../../Types/Termination';
import { PagePagination } from '../../PagePagination';

export const Step1Form: React.FC = () => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm<TerminationFormInputsStep1>();
    const { currentPage, setCurrentPage } = useCurrentStepState();

    const terminationFormState = useTerminationFormState((state) => state.step1);
    const setTerminationFormStep1 = useTerminationFormState((state) => state.setStep1);

    const terminationReasons = useTerminationReasonState((state) => state.terminationReason);

    const { token } = useMcaptchaState();

    const locations = useLocationsStateType((state) => state.locations);
    const setLocations = useLocationsStateType((state) => state.setLocations);

    const contractLocations = useLocationsStateType((state) => state.contractLocations);
    const setContractLocations = useLocationsStateType((state) => state.setContractLocations);

    const formFieldValues = watch();
    const email = watch('email');

    const zip = watch('zip');
    const contractZip = watch('contractZip');

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setTerminationFormStep1(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<TerminationFormInputsStep1> = async () => {
        if (Object.keys(formFieldValues).length > 0) {
            setTerminationFormStep1(formFieldValues);
        }
        setCurrentPage(currentPage + 1);
    };

    const onHandleClickNextStep = (): void => {
        // check if form has no errors
        if (token) {
            setValue('token', token);
        }

        if (isValid) {
            HelperService.scrollToStart();
        }
    };
    useEffect(() => {
        Object.keys(terminationFormState).forEach((key) => {
            setValue(key, terminationFormState[key]);
        });
        // eslint-disable-next-line
    }, []);

    const getLocationByPlz = async (zip: string, setMethod: (locations: Locations[]) => void, fieldname: string) => {
        setLoading(true);
        const locations = await ApiService.getLocationByPlz.post(zip);
        setMethod(locations.data);
        setLoading(false);
        if (locations.data.length > 0) {
            setValue(fieldname, locations.data[0].city);
        }
    };

    useEffect(() => {
        if (contractZip && HelperService.pattern.zipCodeRegex.test(contractZip)) {
            getLocationByPlz(contractZip, setContractLocations, 'contractCity');
        }
        // eslint-disable-next-line
    }, [contractZip]);

    useEffect(() => {
        if (zip && HelperService.pattern.zipCodeRegex.test(zip)) {
            getLocationByPlz(zip, setLocations, 'city');
        }
        // eslint-disable-next-line
    }, [zip]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Kündigungsart</h2>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <div className="form__radio-container">
                        <div className="form__radio radio">
                            <input
                                type="radio"
                                value="0"
                                id="Termination0"
                                defaultChecked={terminationFormState.terminationType === 'fristgerechte Kündigung'}
                                {...register('terminationType', {
                                    required: 'Dieses Feld ist ein Pflichtfeld.',
                                })}
                            />
                            <label className="powermail_label" htmlFor="Termination0">
                                fristgerechte Kündigung
                            </label>
                        </div>
                        <div className="form__radio radio">
                            <input
                                type="radio"
                                value="1"
                                id="Termination1"
                                defaultChecked={terminationFormState.terminationType === 'außerordentliche Kündigung'}
                                {...register('terminationType', {
                                    required: 'Dieses Feld ist ein Pflichtfeld.',
                                })}
                            />
                            <label className="powermail_label" htmlFor="Termination1">
                                außerordentliche Kündigung
                            </label>
                        </div>

                        {errors.terminationType && (
                            <div
                                className="powermail_error"
                                style={{
                                    marginTop: '-20px',
                                    marginBottom: '40px',
                                    color: 'red',
                                }}>
                                {errors.terminationType.message}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {formFieldValues.terminationType && (
                <Fragment>
                    <div className="grid">
                        {formFieldValues.terminationType === '0' && (
                            <Fragment>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p>
                                        Bitte beachten Sie, dass eine Kündigung zum nächstmöglichen Termin erfolgt. <br />
                                        Es gelten die vereinbarten Kündigungsfristen. <br />
                                        Sollten Sie einen späteren Zeitpunkt wünschen können Sie diesen hier angeben.
                                    </p>
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6"></div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="orderFirstName">
                                        Zu welchem Termin möchten Sie kündigen?
                                    </label>
                                    <input type="date" id="terminationText" defaultValue={terminationFormState.terminationDate} {...register('terminationDate', {})} />

                                    {errors.terminationDate && (
                                        <div
                                            className="powermail_error"
                                            style={{
                                                marginTop: '-20px',
                                                marginBottom: '40px',
                                                color: 'red',
                                            }}>
                                            {errors.terminationDate.message}
                                        </div>
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <div className="form__select-container">
                                <label className="powermail_label" htmlFor="healthInsurance">
                                    {/* eslint-disable-next-line eqeqeq */}
                                    Grund:{' '}
                                    {formFieldValues.terminationType == '1' && (
                                        <Fragment>
                                            <span className="powermail_mandatory malteserrot">*</span>
                                        </Fragment>
                                    )}
                                </label>
                                <div className="form__dropdown select">
                                    <select
                                        id="termination"
                                        {...register('termination', {
                                            required: {
                                                // eslint-disable-next-line eqeqeq
                                                value: formFieldValues.terminationType == '1',
                                                message: 'Dieses Feld ist ein Pflichtfeld.',
                                            },
                                        })}>
                                        <option value="">Bitte wählen Sie</option>
                                        {terminationReasons &&
                                            // eslint-disable-next-line eqeqeq
                                            terminationReasons
                                                // eslint-disable-next-line eqeqeq
                                                .filter((terminationReason) => terminationReason.type == formFieldValues.terminationType)
                                                .map((terminationReason) => (
                                                    <option key={terminationReason.id} value={terminationReason.name} defaultValue={terminationFormState.termination}>
                                                        {terminationReason.name}
                                                    </option>
                                                ))}
                                    </select>
                                    {/* eslint-disable-next-line eqeqeq */}
                                    {errors.termination && formFieldValues.terminationType == '1' && (
                                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                            {errors.termination.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {formFieldValues.terminationType === '1' && (formFieldValues.termination === 'Verstorben' || formFieldValues.termination === 'Umzug ins Heim') && (
                            <Fragment>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="orderFirstName">
                                        Datum: <span className="powermail_mandatory malteserrot">*</span>
                                    </label>
                                    <input type="date" id="terminationText" defaultValue={terminationFormState.terminationDate} {...register('terminationDate', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />

                                    {errors.terminationDate && (
                                        <div
                                            className="powermail_error"
                                            style={{
                                                marginTop: '-20px',
                                                marginBottom: '40px',
                                                color: 'red',
                                            }}>
                                            {errors.terminationDate.message}
                                        </div>
                                    )}
                                </div>
                            </Fragment>
                        )}

                        {formFieldValues.termination === 'Sonstiges' && (
                            <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                <label className="powermail_label" htmlFor="orderFirstName">
                                    Sonstiges:
                                </label>
                                <input type="text" id="terminationText" defaultValue={terminationFormState.terminationText} {...register('terminationText')} />
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
            <h2>Ihre Daten</h2>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <div className="form__radio-container">
                        <div className="form__radio radio">
                            <input type="radio" value="Herr" id="SalutationMan" defaultChecked={terminationFormState.salutation === 'Herr'} {...register('salutation')} />
                            <label className="powermail_label" htmlFor="SalutationMan">
                                Herr
                            </label>

                            <input type="radio" value="Frau" id="SalutationWoman" defaultChecked={terminationFormState.salutation === 'Frau'} {...register('salutation')} />
                            <label className="powermail_label" htmlFor="SalutationWoman">
                                Frau
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderFirstName">
                        Vorname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="firstName" defaultValue={terminationFormState.firstName} {...register('firstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.firstName && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.firstName.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderLastname">
                        Nachname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="lastName" defaultValue={terminationFormState.lastName} {...register('lastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.lastName && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.lastName.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <label className="powermail_label" htmlFor="street">
                        Stra&szlig;e: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="street" defaultValue={terminationFormState.street} {...register('street', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.street && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.street.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="houseNumber">
                                Hausnummer: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="houseNumber" defaultValue={terminationFormState.houseNumber} {...register('houseNumber', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.houseNumber && (
                                <div
                                    className="powermail_error"
                                    style={{
                                        marginTop: '-20px',
                                        marginBottom: '40px',
                                        color: 'red',
                                    }}>
                                    {errors.houseNumber.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="zip">
                                PLZ: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input
                                type="number"
                                id="zip"
                                defaultValue={terminationFormState.zip}
                                {...register('zip', {
                                    required: 'Dieses Feld ist ein Pflichtfeld.',
                                    minLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' },
                                    maxLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' },
                                })}
                            />
                            {errors.zip && (
                                <div
                                    className="powermail_error"
                                    style={{
                                        marginTop: '-20px',
                                        marginBottom: '40px',
                                        color: 'red',
                                    }}>
                                    {errors.zip.message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="city">
                        Stadt: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <select id="city" {...register('city', { required: 'Bitte wählen Stadt aus!' })}>
                        {locations &&
                            locations.map((location) => (
                                <option key={location.city} value={location.city}>
                                    {location.city}
                                </option>
                            ))}
                    </select>
                    {errors.city && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.city.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="phone">
                        Telefon: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="number" id="phone" defaultValue={terminationFormState.phone} {...register('phone', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.phone && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.phone.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="email">
                        E-Mail: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        defaultValue={terminationFormState.email}
                        {...register('email', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            pattern: {
                                value: HelperService.pattern.emailRegex,
                                message: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
                            },
                        })}
                    />
                    {errors.email && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.email.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="emailCopy">
                        E-Mail bestätigen: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="email"
                        id="emailCopy"
                        defaultValue={terminationFormState.emailCopy}
                        {...register('emailCopy', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            validate: {
                                emailValid: (value) => HelperService.pattern.emailRegex.test(value) || 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
                                emailEqual: (value) => value === email || 'Die E-Mails stimmen nicht überein.',
                            },
                        })}
                    />
                    {errors.emailCopy && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.emailCopy.message}
                        </div>
                    )}
                </div>
            </div>

            <h2>Leistungsempfänger</h2>
            <div className="form__radio-container">
                <p className="label-style">Sind Sie der Leistungsempfänger/Vertragspartner?</p>
                <div className="form__radio radio">
                    <input type="radio" value="0" id="contractAddress0" defaultChecked={terminationFormState.contractAddress === '0'} {...register('contractAddress', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    <label className="powermail_label" htmlFor="contractAddress0">
                        Ja
                    </label>

                    <input type="radio" value="1" id="contractAddress1" defaultChecked={terminationFormState.contractAddress === '1'} {...register('contractAddress', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    <label className="powermail_label" htmlFor="contractAddress1">
                        Nein
                    </label>
                </div>
                {errors.contractAddress && (
                    <div
                        className="powermail_error"
                        style={{
                            marginTop: '10px',
                            marginBottom: '20px',
                            color: 'red',
                        }}>
                        {errors.contractAddress.message}
                    </div>
                )}
            </div>

            {formFieldValues.contractAddress === '1' && (
                <Fragment>
                    <h2>Vertragspartner</h2>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <div className="form__radio-container">
                                <div className="form__radio radio">
                                    <input type="radio" value="Frau" id="contractSalutationWoman" defaultChecked={terminationFormState.contractSalutation === 'Frau'} {...register('contractSalutation')} />
                                    <label className="powermail_label" htmlFor="contractSalutationWoman">
                                        Frau
                                    </label>

                                    <input type="radio" value="Herr" id="contractSalutationMan" defaultChecked={terminationFormState.contractSalutation === 'Herr'} {...register('contractSalutation')} />
                                    <label className="powermail_label" htmlFor="contractSalutationMan">
                                        Herr
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="contractFirstName">
                                Vorname: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="contractFirstName" defaultValue={terminationFormState.contractFirstName} {...register('contractFirstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.contractFirstName && (
                                <div
                                    className="powermail_error"
                                    style={{
                                        marginTop: '-20px',
                                        marginBottom: '40px',
                                        color: 'red',
                                    }}>
                                    {errors.contractFirstName.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="contractLastName">
                                Nachname: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="contractLastName" defaultValue={terminationFormState.contractLastName} {...register('contractLastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.contractLastName && (
                                <div
                                    className="powermail_error"
                                    style={{
                                        marginTop: '-20px',
                                        marginBottom: '40px',
                                        color: 'red',
                                    }}>
                                    {errors.contractLastName.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <label className="powermail_label" htmlFor="contractStreet">
                                Stra&szlig;e: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="contractStreet" defaultValue={terminationFormState.contractStreet} {...register('contractStreet', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.contractStreet && (
                                <div
                                    className="powermail_error"
                                    style={{
                                        marginTop: '-20px',
                                        marginBottom: '40px',
                                        color: 'red',
                                    }}>
                                    {errors.contractStreet.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="contractHouseNumber">
                                        Hausnummer: <span className="powermail_mandatory malteserrot">*</span>
                                    </label>
                                    <input type="text" id="contractHouseNumber" defaultValue={terminationFormState.contractHouseNumber} {...register('contractHouseNumber', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                                    {errors.contractHouseNumber && (
                                        <div
                                            className="powermail_error"
                                            style={{
                                                marginTop: '-20px',
                                                marginBottom: '40px',
                                                color: 'red',
                                            }}>
                                            {errors.contractHouseNumber.message}
                                        </div>
                                    )}
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="contractZip">
                                        PLZ: <span className="powermail_mandatory malteserrot">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="contractZip"
                                        defaultValue={terminationFormState.contractZip}
                                        {...register('contractZip', {
                                            required: 'Dieses Feld ist ein Pflichtfeld.',
                                            minLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' },
                                            maxLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' },
                                        })}
                                    />
                                    {errors.contractZip && (
                                        <div
                                            className="powermail_error"
                                            style={{
                                                marginTop: '-20px',
                                                marginBottom: '40px',
                                                color: 'red',
                                            }}>
                                            {errors.contractZip.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className="powermail_label" htmlFor="contractCity">
                        Stadt: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <select id="contractCity" {...register('contractCity', { required: 'Bitte wählen Stadt aus!' })}>
                        {contractLocations &&
                            contractLocations.map((location) => (
                                <option key={location.city} value={location.city}>
                                    {location.city}
                                </option>
                            ))}
                    </select>
                    {errors.contractCity && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.contractCity.message}
                        </div>
                    )}
                </Fragment>
            )}
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="birthdate">
                        Geburtstag: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="date" id="birthdate" defaultValue={terminationFormState.birthdate} {...register('birthdate', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.birthdate && (
                        <div
                            className="powermail_error"
                            style={{
                                marginTop: '-20px',
                                marginBottom: '40px',
                                color: 'red',
                            }}>
                            {errors.birthdate.message}
                        </div>
                    )}
                </div>
            </div>

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" onClick={onHandleClickNextStep}>
                    Nächste Seite
                </button>
            </PagePagination>
        </form>
    );
};
